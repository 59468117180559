import { h, FunctionComponent } from 'preact';
import * as classes from './Container.module.css';

interface IProps {
    className?: string;
    noMaxWidthRestrictions?: boolean;
    isNarrow?: boolean;
}

const Container: FunctionComponent<IProps> = (props) => {
    const styles = `${classes.container} ${
        props.isNarrow ? classes.narrowContainer : ''
    } ${props.className ? props.className : ''}`.trim();

    return (
        <section
            className={styles}
            {...(props.noMaxWidthRestrictions && {
                style: { maxWidth: '100%' },
            })}
        >
            {props.children}
        </section>
    );
};

export default Container;
