.navigationNav {
    position: fixed;
    inset: 0;
    background: var(--page-background-color);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: var(--z-middle);
}

.navigationList {
    list-style-type: none;
    padding-left: 0;
    text-align: center;
}

.navigationList a {
    color: inherit;
    text-decoration: none;
}

.navItem+.navItem {
    margin-top: 18px;
}

@media (min-width: 600px) {
    .navigationNav {
        display: block;
        position: relative;
        background: unset;
    }

    .navItem {
        margin: 16px 0;
    }

    .navigationNav.active {
        left: 0;
    }

    .navigationList {
        display: flex;
    }

    .navigationList li:not(:last-child) {
        margin-right: 40px;
    }
}