export const PAGES = {
    HOME: '/',
    ANGEBOT: '/angebot/',
    BIO: '/bio/',
    KONTAKT: '/kontakt/',
    IMPRESSUM: '/impressum/',
    DATENSCHUTZ: '/datenschutz/',
    PROJECT_OVERVIEW: '/projekte/',
    BLOG: '/blog/',
};
