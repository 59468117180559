.container {
    margin: 0 auto;
    max-width: var(--page-max-width);
    padding-left: var(--page-gutter);
    padding-right: var(--page-gutter);
    width: 100%;
}

.narrowContainer {
    max-width: var(--narrow-page-max-width);
}
