import { h, FunctionComponent } from 'preact';
import { Link } from 'preact-router/match';
import { PAGES } from '../../core/Navigation/Pages';
import * as classes from './Navigation.module.css'; // Updated path
import CONFIG from '../../core/Config/Config'; // Import the CONFIG

interface NavigationProps {
    isOpen: boolean;
    close: () => void;
}

const NAV = [
    {
        name: 'Angebot',
        href: PAGES.ANGEBOT,
    },
    {
        name: 'Projekte',
        href: PAGES.PROJECT_OVERVIEW,
    },
    {
        name: 'Bio',
        href: PAGES.BIO,
    },
    {
        name: 'Kontakt',
        href: PAGES.KONTAKT,
    },
    {
        name: 'Blog',
        href: PAGES.BLOG,
    },
];

const Navigation: FunctionComponent<NavigationProps> = ({ isOpen, close }) => {
    const filteredNav = NAV.filter(item => item.name !== 'Blog' || CONFIG.blog);

    return (
        <nav
            className={classes.navigationNav}
            style={{ display: isOpen ? 'flex' : 'none' }}
        >
            <ul className={classes.navigationList}>
                {filteredNav.map((item) => (
                    <li className={classes.navItem} key={item.name}>
                        <Link href={item.href} onClick={close}>
                            {item.name}
                        </Link>
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export default Navigation;